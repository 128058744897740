<template>
  <div>
    <TagAlerts />
  </div>
</template>

<script>
import TagAlerts from "./alerts/TagAlerts";

export default {
  name: "GlobalAlertComponent",
  components: { TagAlerts }
}
</script>

<style scoped>

</style>