<script>
import { layoutComputed } from "@/state/helpers";
import Vertical from "./vertical";
import Horizontal from "./horizontal";
import GlobalAlertComponent from "@/components/globalAlerts/GlobalAlertComponent";

export default {
  components: { Vertical, Horizontal, GlobalAlertComponent },
  data() {
    return {};
  },
  computed: {
    ...layoutComputed
  },
  methods: {
    makeToast(variant = null) {
      this.$bvToast.toast("Toast body content", {
        title: `Toast ${variant || "default"}`,
        variant: variant,
        solid: true
      });
    },
  },
  mounted() {
    this.no
  }
};
</script>

<template>
  <div>
    <vertical v-if="layoutType === 'vertical'" :layout="layoutType">
      <GlobalAlertComponent />
      <template v-slot:topbar><slot name="topbar"></slot></template>
      <slot />
    </vertical>

    <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
      <slot />
    </Horizontal>
  </div>
</template>
