<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  methods: {
    showPrevButton: function () {
      if (this.items[this.items.length - 2].href === undefined) {
        return false
      }
      return true
    },
    goToLastCrumb: function () {
      this.$router.push(this.items[this.items.length - 2].href)
    }
  }
}
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <div class="d-flex">
          <b-button
              v-if="showPrevButton()"
              variant="outline-dark"
              size="sm"
              class="p-0 pl-1 pr-1 mr-2 border-0"
              @click="goToLastCrumb"
              v-b-tooltip.viewport.hover="$t('General.Base.Back')"
          >
            <i class="fas fa-angle-double-left"></i>
          </b-button>
          <h4 class="mb-0">{{ title }}</h4>
        </div>
        <div class="page-title-right">
          <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
