<template>
  <b-dropdown
      id="projectSelect"
      variant="light"
      class="mb-3"
      v-b-tooltip.hover
      :title="$t('Projects.LeftBar.DropdownSelectTooltip')"
  >
    <template slot="button-content" >
      <div
          v-if="currentProjects"
          class="font-size-18 m-1">
        <i class="mdi mdi-folder-open mr-2"></i>
        <span class="projectName">{{currentProjects.name}}</span>

        <i class="mdi mdi-chevron-down"></i>
      </div>
      <div v-else>
          <i class="mdi mdi-plus"></i>
          {{$t('Projects.AddProject.AddProject')}}
      </div>
    </template>

    <b-dropdown-item
      v-for = "(project, projectId) in projects"
      :key = "projectId"
      @click = "selectProject(projectId)"
    >
      <i class="mdi mdi-folder"></i>
      {{project.name}}
    </b-dropdown-item>
    <b-dropdown-item>
      <router-link class="pl-0" to="/projects/0">
        <i class="mdi mdi-plus"></i>
        {{$t('Projects.AddProject.AddProject')}}
      </router-link>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>

export default {
  name: "projectSelect",
  computed: {
    projects: function() {
      return this.$store.getters['projects/getProjects']
    },
    currentProjects: function() {
      return this.$store.getters['projects/getCurrentProjects']
    }
  },
  methods: {
    selectProject(projectId) {
      this.$store.dispatch('projects/selectProject', projectId).then(() => {
        // console.log(data)
      })
      // this.$router.push('/')
    }
  }

}
</script>

<style scoped>
</style>
