<template>
  <b-alert v-if="notSaveTag" show variant="warning">
    {{$t('General.GlobalAlerts.NotSavedTag.NotSavedAlert')}}
    <b-button variant="success" class="ml-3" size="sm" @click="goToTag">{{$t('Tags.General.GoToTag')}}</b-button>
    <b-button variant="danger" class="ml-2" size="sm" @click="deleteUnsaveTag">{{$t('General.Base.Delete')}}</b-button>
  </b-alert>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "TagAlerts",
  computed: {
    notSaveTag: function() {
      if (this.$route.name === 'TagEdit') {
        return false
      }
      if (!this.$store.getters['tag/hasLocalStorageTag']) {
        return false
      }
      return true
    }
  },
  methods: {
    goToTag: function() {
      this.$router.push('/tags/0')
    },
    deleteUnsaveTag: function() {
      Swal.fire({
        title: this.$t('General.Base.AreYouSure'),
        text: this.$t('General.GlobalAlerts.NotSavedTag.DeleteModalText'),
        icon: "warning",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t('General.Base.Yes'),
        cancelButtonText: this.$t('General.Base.No'),
      }).then(() => {
        this.$store.dispatch('tag/clearTagLocal')
      })

    }
  }
}
</script>

<style scoped>

</style>